import React from "react";
import { Helmet } from 'react-helmet';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import CountUp from 'react-countup';
import 'animate.css';
import Swal from "sweetalert2";
import 'react-circular-progressbar/dist/styles.css';
import Footer from "./Footer";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';
import Typewriter from "typewriter-effect";
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Header from "./Header";
import DOMPurify from 'isomorphic-dompurify';
import 'react-datepicker/dist/react-datepicker.css';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Job: [],
            Description: '',
            name: '',
            email: '',
            number: '',
            subject: '',
            message: '',
            toDate: '',
            blog: [],
            formErrors: {},
            setContactFormBool: false,
            show: false,
            isOpen: false,
            isActive: false,
            selectedDate: null,
            isAnimationRunning: true,
            autoplay: true,
            clientfidback: []
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.onClickShowdetails = this.onClickShowdetails.bind(this);
        this.maxlengthnumber = this.maxlengthnumber.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        sessionStorage.setItem("yearOfExp", moment().diff(moment("2016-07-31"), "year").toString());
        console.log("YD", moment().format("YYYY").toString());
        sessionStorage.setItem("currentYear", moment().format("YYYY").toString());
        sessionStorage.setItem("HOST_API_URL", 'https://www.oengines.com/');
    }

    openModal(e) {
        this.setState({ isOpen: !this.state.isOpen });
    }
    handleOpenContectModal(e) {
        this.setState({ show: !this.state.show });
        console.log("state is::::::::", this.state.show);
    }
    handlevideopopup(e) {
        console.log("::::::add on this:");
        this.setState({ isActive: !this.state.isActive });
    }
    onContactFormHide(e) {
        console.log(" event ::::onContactFormHide:: ", this.state.setContactFormBool);
        this.setState({ setContactFormBool: false });
    }
    onContactFormShow(e) {
        console.log(" event ::onContactFormShow:::: ", this.state.setContactFormBool);        
        this.setState({ show: !this.state.show })
    }
    onClickShowdetails(job) {
        console.log("job is:::::::", job);
        localStorage.setItem("Job", JSON.stringify(job));
        window.location.href = '/careerdetail';
    }
    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    endDateChange(e) {
        this.setState({ toDate: e.target.value })
    }
    handleChangedatepicker = (date) => {
        this.setState({
            selectedDate: date,
        });
    };
    onClickblogdetails(blog) {
        console.log("blog is:::::::", blog);
        localStorage.setItem("Blog", JSON.stringify(blog));
        window.location.href = '/blogdetails';
    }
    closeModal() {
        this.setState({ show: !this.state.show });
    };
    handleClickInsideModal = (event) => {
        event.stopPropagation();
    }
    maxlengthnumber(evt) {
        if (evt.target.value.length === 11) {
            return false;
        }
        else {
            this.setState({ ...this.state, [evt.target.name]: evt.target.value })
        }
    }
    handleScroll() {
        if (window.scrollY > 100) {
            this.setState({ isScrolled: true });
        } else {
            this.setState({ isScrolled: false });
        }
    }
    onSubmit(e) {
        e.preventDefault();
        let formErrors = {};
        console.log("Enter in::::::::::::");
        if (this.state.name === '' || this.state.name === null) {

            formErrors["nameError"] = "Name is required.";
            this.refs.namefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });
            return false;
        }
        else if (this.state.email === '' || this.state.email === null) {
            formErrors["emailError"] = "Email id is required.";
            this.refs.emailfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.subject === '' || this.state.subject === null) {
            formErrors["subjectError"] = "Subect is required.";
            this.refs.subjectfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.number === '' || this.state.number === null) {
            formErrors["numberError"] = "Number is required.";
            this.refs.numberfield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        }
        else if (this.state.message === '' || this.state.message === null) {
            formErrors["messageError"] = "Message is required.";
            this.refs.messagefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else if (this.state.toDate === '' || this.state.toDate === null) {
            formErrors["toDateError"] = "Date is required.";
            this.refs.toDatefield.focus();
            this.setState({ formErrors: formErrors, classForValid: 'form-control is-invalid' });

            return false;
        } else {

            const formObject = {
                name: this.state.name,
                email: this.state.email,
                number: this.state.number,
                subject: this.state.subject,
                toDate: this.state.toDate,
                message: this.state.message,

            };
            console.log("formObject::::::::::;", formObject);
            axios.post("https://www.oengines.com/contactus", formObject)
                .then((res) => {
                    console.log("Response is:::::::::", res);
                    if (res.data !== 'undefined') {
                        Swal.fire({
                            title: "Thank you!",
                            text: "Your request has been assigned successfully.",
                            icon: "success",
                        }).then(function () {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: "Oh! Something went wrong, please try again!",
                            icon: "error",
                        }).then(function () {
                            window.location.reload();
                        });
                    }

                });
        }

    }

    render() {



        const sliderSettings = {
            dots: false,
            items: 5,
            slidestoscroll: 1,
            autoplay: true,
            interval: 5000,
            initialslide: 0,
            autoplaytimeout: 8000,
            autoplaySpeed: 2000,
            responsive: {
                0: {
                    items: 2,
                    nav: false
                },
                600: {
                    items: 3,
                    nav: false
                },
                1000: {
                    items: 4,
                    nav: false,
                }
            }           
        }
        const sliderone = {
            dots: false,
            items: 1,
            slidestoscroll: 1,
            autoplay: true,
            interval: 5000,
            initialslide: 0,
            autoplaytimeout: 5000,
            autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        items: 2,
                        slidestoscroll: 1,
                        infinite: true,
                        dots: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        items: 1,
                        slidestoscroll: 1,
                        infinite: true,
                        dots: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        items: 1,
                        slidestoscroll: 1,
                        infinite: true,
                        dots: false,
                    }
                }
            ]

        }
        const slidertwo = {
            dots: false,
            items: 1,
            slidestoscroll: 1,
            autoplay: true,
            interval: 2000,
            initialslide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        items: 2,
                        slidestoscroll: 1,
                        infinite: true,
                        dots: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        items: 1,
                        slidestoscroll: 1,
                        infinite: true,
                        dots: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        items: 1,
                        slidestoscroll: 1,
                        infinite: true,
                        dots: false,
                    }
                }
            ]

        }
        
        const myStyleone = {
            backgroundImage:
                "url('../assets/img/bg/hero-bg/UGS-Web-Hero.jpg')",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            opacity: 0.9,
        };
        const mystyletow = {
            backgroundImage:
                "url('../assets/img/bg/hero-bg/slide-bg-performance.jpg')",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            opacity: 0.9,
            backgroundSize: "cover",
        };
        const mystylethree = {
            backgroundImage:
                "url('../assets/img/bg/hero-bg/app.jpg')",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            backgroundSize: "cover",
            left: "0px",
            zIndex: "2",
            opacity: "0.9",
            backgroundPosition: "initial",
        };
        const mystylefour = {
            backgroundImage:
                "url('../assets/img/bg/hero-bg/cta_bg_1.png')",
            background: '#1c1c1c'
        };
        const mystylefive = {
            backgroundImage:
                "url('../assets/img/bg/service_bg_2.jpg')",
            backgroundRepeat: 'no-repeat',
            zIndex: "9"
        }
        const mystylesix = {            
            backgroundColor: "#f3f3f3d4",
            backgroundRepeat: 'no-repeat'
        }
        const settings = {
            /* animateOut: 'flipOutY',
            animateIn: 'flipInY', */
            items: 1,
            loop: true,
            dots: false,
            nav: false,
            autoplayHoverPause: false,
            autoplay: false //this.state.autoplay,
        };
        const { nameError, emailError, subjectError, numberError, toDateError, messageError } = this.state.formErrors;
        var Information;
        if (this.state.Job.length) {
            Information = this.state.Job.map((job, Index) =>
                <>
                    <li key={Index}>
                        <a href="#" onClick={() => this.onClickShowdetails(job)}>{job.job}</a>
                    </li>
                </>
            )
        } else {
            Information = <div className="text-center"><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }
        var latestpostdata;
        if (this.state.blog.length) {
            var latestpostdata = <OwlCarousel className='owl-theme' loop margin={10} {...sliderone} animationdelay="3s" nav>
                {this.state.blog.map((blog, index) => {
                    return <>
                        <article>
                            <div className="row justify-content-center">
                                <div className="col-auto pe-0">
                                    <div className="date latest_date">
                                        {moment(blog.createdat).format('YYYY-MM-DD')}
                                    </div>
                                </div>
                                <div className="col ps-1 col-md-10 col-sm-10 col-12 col-lg-8">
                                    <h4 className="text-primary text-4"><a className="d-block" href="#!" onClick={() => this.onClickblogdetails(blog)} >{blog.blogtitle}
                                    </a>
                                    </h4>
                                    <div className="box" id="section">
                                        <div className="content-full article" id="article" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.sub_title) }}></div>
                                    </div>
                                    <a href="#!" onClick={() => this.onClickblogdetails(blog)} className="read-more text-color-dark font-weight-semibold text-2">read more
                                        <i className="fas fa-angle-right position-relative top-1 ms-1"></i>
                                    </a>
                                </div>
                            </div>
                        </article>

                    </>
                }
                )}
            </OwlCarousel>
        } else {
            latestpostdata = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }

        var clientfideback;
        if (this.state.clientfidback.length) {
            var clientfideback = <OwlCarousel className='owl-theme' loop margin={10} {...slidertwo} nav>
                {this.state.clientfidback.map((clientfidback, index) => {
                    return <>
                        <div>
                            <div className="col">
                                <div className="testimonial testimonial-primary">
                                    <blockquote>
                                        <p className="mb-0">{clientfidback.review}</p>
                                    </blockquote>
                                    <div className="testimonial-arrow-down"></div>
                                    <div className="testimonial-author d-flex">
                                        <div className="testimonial-author-thumbnail">
                                        </div>
                                        <p><strong>{clientfidback.name}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                )}
            </OwlCarousel>
        } else {
            clientfideback = <div><h4 style={{ color: "Red", fontSize: "20px" }}>No Record Found !</h4></div>;
        }


        const { selectedDate } = this.state;
        const AutoplaySlider = withAutoplay(AwesomeSlider);
        const { isAnimationRunning } = this.state;
        const pattern = /\/example/;

        return (
            <div>
                <Helmet>                  
                    <link href="https://owlcarousel2.github.io/OwlCarousel2/assets/css/animate.css" rel="stylesheet" />
                    {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.0/assets/owl.theme.default.css" />
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.0/assets/owl.carousel.css" />      */}           
                    <link rel="stylesheet" href="../assets/css/Slick_Cdn/animate.min.css" />
                    <link rel="stylesheet" href="../assets/css/Slick_Cdn/Datepicker.css" />                    
                    <link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" />
                    <link rel="stylesheet" href="../../assets/css/Slick_Cdn/Slic-min.css" />                    
                    <script src="../assets/js/jquery-min.js"></script>
                    <script src="../assets/js/Tweenlite-min.js"></script>
                    <script src="../assets/js/raf.js"></script>
                    <script src="../assets/js/easepack-min.js"></script>
                    <script src="../assets/js/tsparticle-engine.js"></script>
                    <script src="../assets/js/tsparticles-bundle.js"></script>
                    <script src="../assets/js/jquery-particles.js"></script>
                    <script src="../assets/js/tsparticles-min.js"></script>
                    <script src="../assets/js/slick-min.js"></script>
                    <script src="../assets/js/tsparticles-min.js"></script>
                    <script src="../assets/js/particles-min.js"></script>
                    <script src="../assets/js/jquery-min-2.1.3.js"></script>
                    <script src="../assets/js/Datetimepicker.full.min.js"></script>                   
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.0/jquery.min.js" type="javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.0/owl.carousel.js" type="javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/js/bootstrap.min.js" type="javascript"></script>
                    <script src="../assets/js/script.js" type="text/Javascript"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/1.12.4/jquery.min.js" type="text/Javascript"></script>
                    <script src="https://owlcarousel2.github.io/OwlCarousel2/assets/owlcarousel/owl.carousel.js" type="text/Javascript"></script>
                </Helmet>
                {/* <div className="loader"><span>Loading...</span></div> */}

                <Modal show={this.state.show} onClick={this.handleClickInsideModal} className="modal_contect" centered >
                    <Modal.Header closeButton onHide={(e) => this.handleOpenContectModal()}>
                        <h5 className="modal-title m-auto" id="exampleModalLabel">ContactUs</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.onSubmit} method="post" className="contact-form ajax-contact">
                            <div className="row">
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-user"></i>
                                    {nameError &&
                                        <div className="invalid-feedback" >{nameError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-envelope"></i>
                                    {emailError &&
                                        <div className="invalid-feedback">{emailError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                        <option disabled="disabled">Select Subject
                                        </option>
                                        <option defaultValue={"Web Development"}>Web Development</option>
                                        <option defaultValue={"Brand Marketing"}>Brand Marketing</option>
                                        <option defaultValue={"UI/UX Designing"}>UI/UX Designing</option>
                                        <option defaultValue={"Digital Marketing"}>Digital Marketing</option>
                                    </select>
                                    {subjectError &&
                                        <div className="invalid-feedback">{subjectError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={this.maxlengthnumber} value={this.state.number} />
                                    <i className="fal fa-phone"></i>
                                    {numberError &&
                                        <div className="invalid-feedback">{numberError}</div>
                                    }
                                </div>
                                <div className="form-group col-12">
                                    <input className="form-control datetimepickerone d-desktop" id="datetimepickerone" name="toDate" onBlur={this.endDateChange} type="text" ref="toDatefield" autoComplete="off" placeholder="Appointment Date" />
                                    <i className="fal fa-calendar"></i>
                                    {toDateError &&
                                        <div className="invalid-feedback">{toDateError}</div>
                                    }
                                </div>

                                <div className="form-group col-12">
                                    <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                    </textarea>
                                    <i className="fal fa-comment"></i>
                                    {messageError &&
                                        <div className="invalid-feedback">{messageError}</div>
                                    }
                                </div>
                                <div className="form-btn text-xl-start text-center col-12">
                                    <button type="submit" className="as-btn style3 send-qty">Send Details
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"></p>
                        </form>

                    </Modal.Body>
                </Modal>
                <Modal show={this.state.isOpen} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal" centered>
                    <Modal.Header closeButton onHide={() => this.openModal()}>
                        <h5 className="modal-title m-auto" id="exampleModalLabel">Get A Quote</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.onSubmit} method="post" className="contact-form  quote-form ajax-contact">
                            <div className="row">
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-user"></i>
                                    {nameError &&
                                        <div className="invalid-feedback" >{nameError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                    <i className="fal fa-envelope"></i>
                                    {emailError &&
                                        <div className="invalid-feedback">{emailError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                        <option disabled="disabled">Select Subject
                                        </option>
                                        <option defaultValue={"App Development"}>App Development</option>
                                        <option defaultValue={"Game Development"}>Game Development</option>
                                        <option defaultValue={"Web Development"}>Web Development</option>
                                        <option defaultValue={"Brand Marketing"}>Brand Marketing</option>
                                        <option defaultValue={"UI/UX Designing"}>UI/UX Designing</option>
                                        <option defaultValue={"Digital Marketing"}>Digital Marketing</option>
                                    </select>
                                    {subjectError &&
                                        <div className="invalid-feedback">{subjectError}</div>
                                    }
                                </div>
                                <div className="form-group col-md-6 col-sm-6">
                                    <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" maxLength={10} onChange={this.maxlengthnumber} value={this.state.number} />
                                    <i className="fal fa-phone"></i>
                                    {numberError &&
                                        <div className="invalid-feedback">{numberError}</div>
                                    }
                                </div>

                                <div className="form-group col-12">
                                    <textarea name="message" id="message" cols="30" rows="30" ref="messagefield"
                                        className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                    </textarea>
                                    <i className="fal fa-comment"></i>
                                    {messageError &&
                                        <div className="invalid-feedback">{messageError}</div>
                                    }
                                </div>
                                <div className="form-btn text-xl-start text-center col-12">
                                    <button type="submit" className="as-btn style3 send-qty">Send QUOTE
                                        <i className="fa-regular fa-arrow-right ms-2"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="form-messages mb-0 mt-3"></p>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={this.state.isActive} onClick={this.handleClickInsideModal} className="modal_contect video-effect-modal modalvideos" centered>
                    <Modal.Header closeButton className="modal_header p-0 border-none" style={{ border: "none" }} onHide={() => this.handlevideopopup()} >
                    </Modal.Header>
                    <Modal.Body>
                        <iframe width="960" height="415" src="https://www.youtube.com/embed/fiwb3PBkZIM?autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Modal.Body>
                </Modal>
                <Header />
               
                <div className="as-hero-wrapper hero-2" id="hero">
                    <div className="hero-slider-2">
                        <OwlCarousel className="owl-theme" {...settings} loop margin={10} items={1}>
                            <div className="as-hero-slide">
                                <div className="as-hero-bg as-hero-bg-one" data-bg-src="../public/assets/img/bg/UGS-Web-Hero.jpg" style={myStyleone}
                                    id="top"></div>
                                <div className="container">
                                    <div className="hero-style2">
                                        <div className="ripple-shape"><span className="ripple-1"></span><span
                                            className="ripple-2"></span><span className="ripple-3"></span><span
                                                className="ripple-4"></span><span className="ripple-5"></span><span
                                                    className="ripple-6"></span></div>
                                        <span className="hero-subtitle fadeInLeft" data-ani="fadeInLeft" data-ani-delay="0.1s">We
                                            Create Excellence</span>
                                        <h1 className="hero-title" id="text-typing">
                                            <Typewriter options={{ strings: ['Game', 'App', 'Web'], autoStart: true, loop: true, }} />
                                            <span>Development</span>
                                        </h1>
                                        <p className="hero-text" data-ani="slideinleft" data-ani-delay="0.7s">Oengines offers a
                                            complete range of gaming solutions for various platforms and devices. Our large team of
                                            game developers and designers offers the most comprehensive solutions that guarantee the
                                            best results for your game.</p>
                                        <div className="btn-group" data-ani="slideinleft" data-ani-delay="0.9s">
                                            <a href="#games" className="as-btn btn-6 style-new">DISCOVER MORE<i
                                                className="fa-regular fa-arrow-right ms-2"></i></a>
                                            <a href="/contact"
                                                className="as-btn style2">CONTACT US<i
                                                    className="fa-regular fa-arrow-right ms-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="as-hero-slide mt-md-30 mobile-hero-slide">
                                <div className="as-hero-bg as-hero-bg-two" data-bg-src="../assets/img/bg/hero_app.jpg" style={mystyletow}></div>
                                <div className="container mt-28">
                                    <div className="hero-style2">
                                        <div className="ripple-shape"><span className="ripple-1"></span><span
                                            className="ripple-2"></span><span className="ripple-3"></span><span
                                                className="ripple-4"></span><span className="ripple-5"></span><span
                                                    className="ripple-6"></span></div>
                                        <span className="hero-subtitle" data-ani="slideinleft" data-ani-delay="0.1s">Beyond Your
                                            Imagination</span>
                                        <h1 className="hero-title fadeInLeft" data-ani="slideinleft" data-ani-delay="0.3s">
                                            <Typewriter options={{
                                                strings: ['2D', '2.5D', '3D'], autoStart: true, loop: true
                                            }} />
                                            GAMES
                                        </h1>
                                        <h1 className="hero-title" data-ani="slideinleft" data-ani-delay="0.5s"> </h1>
                                        <p className="hero-text" data-ani="slideinleft" data-ani-delay="0.7s">We offer a variety of programs and services, <br></br>Dive into the World of Immersive Gaming: We are Creating 2D / 3D Masterpieces</p>
                                        <div className="btn-group" data-ani="slideinleft" data-ani-delay="0.9s"><a href="#games"
                                            className="as-btn style-new">DISCOVER MORE<i
                                                className="fa-regular fa-arrow-right ms-2"></i></a> <a href="/contact"
                                                    className="as-btn style2">CONTACT US<i
                                                        className="fa-regular fa-arrow-right ms-2"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="as-hero-slide mt-md-30 mobile-hero-slide">
                                <div className="as-hero-bg as-hero-bg-three" data-bg-src="../assets/img/hero/hero_bg_2_3.jpg" style={mystylethree}></div>
                                <div className="container">
                                    <div className="hero-style2">
                                        <div className="ripple-shape"><span className="ripple-1"></span><span
                                            className="ripple-2"></span><span className="ripple-3"></span><span
                                                className="ripple-4"></span><span className="ripple-5"></span><span
                                                    className="ripple-6"></span></div>

                                        <span className="hero-subtitle" data-ani="slideinleft" data-ani-delay="0.1s">Passionate about
                                            Work</span>
                                        <div className="aggettivi">
                                            <h1 className="third-slide-text text-animated-one text-white sub-hero-titles"
                                                data-ani="slideinleft" data-ani-delay="0.5s" style={{ fontSize: "71px" }}>Top-Notch
                                                Support</h1>
                                            <h1 className="third-slide-text text-animated-two text-white sub-hero-titles"
                                                data-ani="slideinleft" data-ani-delay="0.5s" style={{ fontSize: "71px" }}>Rapid
                                                Solutions</h1>
                                            <h1 className="third-slide-text text-animated-three text-white sub-hero-titles"
                                                data-ani="slideinleft" data-ani-delay="0.5s" style={{ fontSize: "71px" }}>Innovative
                                                Thinking</h1>
                                        </div>
                                        <p className="hero-text" data-ani="slideinleft" data-ani-delay="0.7s">We are a digital agency
                                            specializing in web design, mobile development and seo optimization.</p>

                                        <div className="btn-group" data-ani="slideinleft" data-ani-delay="0.9s"><a href="#games"
                                            className="as-btn style-new">DISCOVER MORE<i
                                                className="fa-regular fa-arrow-right ms-2"></i></a> <a href="/contact"
                                                    className="as-btn style2" >CONTACT US<i
                                                        className="fa-regular fa-arrow-right ms-2"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>                       
                    </div>
                    <div className="hero-shape2" style={{ zIndex: 10 }}><img src="assets/img/hero/hero_shape_2_2.png" alt="shape" />
                    </div>
                    <div className="hero-shape3" style={{ zIndex: 10 }}><img src="assets/img/hero/hero_shape_2_3.png" alt="shape" />
                    </div>
                </div>
                <section className="bg-top-center z-index-common space-top mt-80 space " style={mystylefive} id="games">
                    <div className="parti-one">
                        <div className="container">

                            <div className="row justify-content-lg-between justify-content-center align-items-center" >
                                <div className="col-lg-6 col-sm-9 pe-xl-5">
                                    <div className="title-area text-center text-lg-start">
                                        <div className="shadow-title color2">SERVICES</div><span className="sub-title"><img
                                            src="assets/img/theme-img/title_shape_2.svg" alt="shape" />Our Expertise</span>
                                        <h4 className="sec-title text-white our-full-services">Our Full-Suite of
                                            <span className="text-theme"> IT Services.</span></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row slider-shadow as-carousel" data-slide-show="4" data-lg-slide-show="3" data-md-slide-show="2"
                                data-sm-slide-show="2" data-xs-slide-show="1">

                                <div className="col-md-4 col-lg-4 col-sm-4 col-xl-4">
                                    <div className="service-grid game-serv-grid">
                                        <div className="service-grid_icon"><img src="assets/img/GameDevelopment/Group-1.svg" alt="Icon" /></div>
                                        <div className="service-grid_content game-service-card" href="/gamedevelopment">
                                            <h3 className="box-title"><a href="/gamedevelopment">Game Development</a></h3>
                                            <p className="service-grid_text">We develop mobile and computer games, like, Multi-player, Fantasy sports, Board, Cards, Skill-based, Strategy games, Arcade, Puzzles, RPG, Action-Adventure, Survival, MMORPG, MOBA, TBT etc.</p>
                                            <div className="bg-shape"><img src="assets/img/bg/service_grid_bg.png" alt="bg" /></div>
                                            <button className="as-btn style2" id="" style={{ border: "none", backgroundColor: "#1c1c1c" }}><a className="text-white read-mores" href="/gamedevelopment">Read More</a><i className="fa-regular fa-arrow-right ms-2"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xl-4" >
                                    <div className="service-grid game-serv-grid">
                                        <div className="service-grid_icon"><img src="assets/img/icon/service_card_1.svg" alt="Icon" /></div>
                                        <div className="service-grid_content web-service-card">
                                            <h3 className="box-title"><a href="/webdevelopment">Web Development</a></h3>
                                            <p className="service-grid_text">We specialize in building responsive, high-performing websites that are visually stunning and user-friendly. Our web solutions are designed to enhance your online presence and effectively engage your audience.
                                                </p>
                                            <div className="bg-shape"><img src="assets/img/bg/service_grid_bg.png" alt="bg" /></div>
                                            <button className="as-btn style2" id="" style={{ border: "none", backgroundColor: "#1c1c1c" }}><a className="text-white read-mores" href="/webdevelopment">Read More</a><i className="fa-regular fa-arrow-right ms-2"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-4 col-xl-4">
                                    <div className="service-grid game-serv-grid">
                                        <div className="service-grid_icon"><img src="assets/img/icon/service_card_3.svg" alt="Icon" /></div>
                                        <div className="service-grid_content mobile-service-card">
                                            <h3 className="box-title"><a href="/mobiledevelopment">Mobile App Development</a></h3>
                                            <p className="service-grid_text">Our app development team crafts intuitive and feature-rich applications tailored to meet diverse business needs and user preferences. We focus on delivering seamless user experiences across all devices.</p>
                                            <div className="bg-shape"><img src="assets/img/bg/service_grid_bg.png" alt="bg" /></div>
                                            <button className="as-btn style2" id="" style={{ border: "none", backgroundColor: "#1c1c1c" }}><a className="text-white read-mores" href="/mobiledevelopment">Read More</a><i className="fa-regular fa-arrow-right ms-2"></i></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <div className="space why_bg" id="about-sec">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 mb-30 mb-xl-0">
                                <div className="img-box1">
                                    <div className="img1"><img src="assets/img/normal/faq_1_1.png" alt="About" /></div>
                                    <div className="year-counter">
                                        <img src="../assets/img/theme-img/yearexperience.svg" alt="img not found" />
                                        <h3 className="year-counter_number "><span className="counter-number" id="foo"><CountUp end={sessionStorage.getItem("yearOfExp")}/>+</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="ps-xxl-4 ms-xl-3 p-4">
                                    <div className="title-area mb-35"><span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_1.svg" alt="shape" />About Oengines</span>
                                        <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                            <div className="d-flex our-sgv-flex">
                                                <div className="abt-svg-icon">
                                                    <img src="../assets/img/icon/megaphone.svg" alt="img not found" />
                                                </div>
                                                <h2 className="sec-title fadeInLeft">
                                                    <span className="">Who we are</span></h2>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="mt-n2 mb-25">Welcome to OENGINES, your one-stop destination for cutting-edge web, app, and game development. We are a team of passionate innovators, creative thinkers, and tech enthusiasts dedicated to transforming ideas into reality.</p></ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <p className="mt-n2 mb-25">We adhere to the highest industry standards and best practices. Our certifications reflect our commitment to quality, security, and professionalism in all aspects of our work. You can trust that our solutions are built with the utmost care and expertise.</p></ScrollAnimation>
                                    <div className="about-feature-wrap">
                                        <div className="about-feature">
                                            <div className="about-feature_icon"><img src="../assets/img/icon/about_feature_1_1.svg"
                                                alt="Icon" /></div>
                                            <div className="media-body">
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <h3 className="about-feature_title">Certified Company</h3>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <p className="about-feature_text">Best Provide Skills Services</p>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                        <div className="about-feature">
                                            <div className="about-feature_icon"><img src="../assets/img/icon/about_feature_1_2.svg"
                                                alt="Icon" /></div>
                                            <div className="media-body">
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <h3 className="about-feature_title">Experts Team</h3>
                                                </ScrollAnimation>
                                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                                    <p className="about-feature_text">100% Experts Team</p>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                    <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                        <div className="btn-group"><a href="tel:+02614054978" className="as-btn style3">Call now<i
                                            className="fa-regular fa-arrow-right ms-2"></i></a>
                                            <div className="call-btn">
                                                <div className="play-btn"><i className="fas fa-phone"></i></div>
                                                <div className="media-body"  ><span className="btn-text">Call Us On:</span> <a
                                                    href="tel:+02614054978" className="btn-title">(0261) - 4054978</a></div>
                                            </div>
                                        </div></ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="space-extra pt-0">
                    <div className="container py-2">
                        <div className="project-abt-theme">
                            <div className="row gy-40 justify-content-between">
                                <div className="col-md-4">
                                    <div className="prd-text mt-15">
                                        <h2 className="text-white">We are proud of our works</h2>
                                        <p className="text-white">We bring solutions to make earning business profit easier for our customers.</p>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="counter-card justify-content-center">
                                                <div className="icons text-center">
                                                    <img src="assets/img/icon/counter_2_1.svg" className="mb-20" alt="Icon" />
                                                    <div className="media-body">
                                                        <h2 className="counter-card_number text-title text-white"><span className="counter-number">800</span>+</h2>
                                                        <p className="counter-card_text text-white">Completed Projects</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="counter-card justify-content-center">
                                                <div className="icons text-center"><img src="assets/img/icon/counter_2_2.svg" className="mb-20" alt="Icon" />
                                                    <div className="media-body">
                                                        <h2 className="counter-card_number text-title text-white"><span className="counter-number">96.7</span>%</h2>
                                                        <p className="counter-card_text text-white">Satisfaction</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="counter-card justify-content-center">
                                                <div className="icons text-center"><img src="assets/img/icon/counter_2_3.svg" className="mb-20" alt="Icon" />
                                                    <div className="media-body">
                                                        <h2 className="counter-card_number text-title text-white"><span className="counter-number">26</span>+</h2>
                                                        <p className="counter-card_text text-white">Skilled Experts</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md">
                                <p className="text-white mt-20">At Oengines, we are immensely proud of the work we do. Each project is a testament to our dedication, creativity, and technical prowess. We take great satisfaction in seeing our clients succeed and our users delighted. Our portfolio showcases a diverse array of successful projects that reflect our capability and passion for delivering exceptional digital solutions.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className="space" id="contact-sec" data-bg-src="assets/img/bg/appointment_bg_1.jpg" style={mystylesix}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 pe-xxl-5 mb-40 mb-xl-0">
                                <div className="title-area mb-35 text-xl-start text-center"><span className="sub-title"><img
                                    src="assets/img/theme-img/title_shape_1.svg" alt="shape" />GET IN TOUCH</span>
                                    <h2 className="sec-title">Have A Game In Mind? Build Your <span
                                        className="text-theme">Dream Game Today.</span></h2>
                                </div>
                                <p className="mt-n2 mb-30 text-xl-start text-center">We’d love to hear from you! Whether you have a question, need support, or just want to say hello, our team is here to help.</p>
                                <p className="mt-n2 mb-30 text-xl-start text-center">Fill out the form, and we’ll get back to you as soon as possible.</p>
                                <div className="contact-feature-wrap">
                                    <div className="contact-feature">
                                        <div className="icon-btn"><i className="fa-solid fa-phone"></i></div>
                                        <div className="media-body">
                                            <p className="contact-feature_label">Call Us On:</p><a href="tel:(0261) - 4054978
"
                                                className="contact-feature_link">(0261) - 4054978
                                            </a>
                                        </div>
                                    </div>
                                    <div className="contact-feature">
                                        <div className="icon-btn"><i className="fa-solid fa-envelope"></i></div>
                                        <div className="media-body">
                                            <p className="contact-feature_label">Quick Mail Us:</p><a href="mailto:support@oengines.com"
                                                className="contact-feature_link">support@oengines.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 ps-xl-4">
                                
                                <form onSubmit={this.onSubmit} method="post" className="appoitment-form ajax-contact">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <input type="text" ref="namefield" className={nameError ? "form-control is-invalid" : "form-control"} name="name" id="name" placeholder="Your Name" onChange={this.handleChange} autoComplete="Off" />
                                            <i className="fal fa-user"></i>
                                            {nameError &&
                                                <div className="invalid-feedback" >{nameError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="email" className={emailError ? "form-control is-invalid" : "form-control"} ref="emailfield" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} autoComplete="Off" />
                                            <i className="fal fa-envelope"></i>
                                            {emailError &&
                                                <div className="invalid-feedback">{emailError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <select name="subject" id="subject" ref="subjectfield" className={subjectError ? "form-control is-invalid" : "form-control"} onChange={this.handleChange} autoComplete="Off">
                                                <option disabled="disabled">Purpose
                                                </option>
                                                <option defaultValue={"Web Development"}>App Development</option>
                                                <option defaultValue={"Web Development"}>Game Development</option>
                                                <option defaultValue={"Web Development"}>Web Development</option>
                                                <option defaultValue={"Brand Marketing"}>Brand Marketing</option>
                                                <option defaultValue={"UI/UX Designing"}>UI/UX Design</option>
                                            </select>
                                            {subjectError &&
                                                <div className="invalid-feedback">{subjectError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input type="number" className={numberError ? "form-control is-invalid" : "form-control"} name="number" value={this.state.number} ref="numberfield" id="number" placeholder="Phone Number" autoComplete="Off" onChange={this.maxlengthnumber} />
                                            <i className="fal fa-phone"></i>
                                            {numberError &&
                                                <div className="invalid-feedback">{numberError}</div>
                                            }
                                        </div>
                                        <div className="form-group col-12">
                                            <input className="form-control datetimepicker" id="datetimepicker" name="toDate" onBlur={this.endDateChange} type="text" ref="toDatefield" autoComplete="off" placeholder="Schedule" />
                                            <i className="fal fa-calendar"></i>
                                            {toDateError &&
                                                <div className="invalid-feedback">{toDateError}</div>
                                            }
                                        </div>

                                        <div className="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" ref="messagefield"
                                                className={messageError ? "form-control is-invalid" : "form-control"} placeholder="Your Message" onChange={this.handleChange} autoComplete="Off">
                                            </textarea>
                                            <i className="fal fa-comment"></i>
                                            {messageError &&
                                                <div className="invalid-feedback">{messageError}</div>
                                            }
                                        </div>
                                        <div className="form-btn text-xl-start text-center col-12 cont-btn">
                                            <button type="submit" className="as-btn style3">Submit
                                                <i className="fa-regular fa-arrow-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-bottom-right position-relative" style={mystylefour} data-bg-color="#080E1C">
                    <div className="img-half img-left as-video2">
                        <img src="assets/img/normal/cta_1.jpg" alt="video" />
                        <p className="play-btn popup-video" id="" onClick={(e) => this.handlevideopopup(e)}><i
                            className="fas fa-play vid-play"></i>
                        </p>
                    </div>
                    <div className="container">
                        <div className="row justify-content-end" >
                            <div className="col-xl-7 space" id="half-particles">
                                <div className="title-area mb-35">
                                    <span className="sub-title"><img
                                        src="assets/img/theme-img/title_shape_1.svg" alt="shape" />WATCH THE VIDEO</span>
                                    <h2 className="sec-title text-white">We Make The Games, That Stand Out From Crowd!</h2>
                                </div>
                                <p className="mt-n2 mb-30 text-white">The gaming industry becoming stable, the requirements for games explored in the market are increasing in the proportional manner day by day. Hence, opting the company to develop your game is a crucial decision. </p>
                                <p className="mt-n2 mb-30 text-white">At [Your Company Name], we are not just game developers; we are passionate visionaries dedicated to crafting immersive and unforgettable experiences. Our mission is to create games that not only entertain but also resonate with players on a deeper level. We take pride in our innovative approach, meticulous attention to detail, and unwavering commitment to excellence.</p>

                                <button className="as-btn" id="" onClick={(e) => this.openModal(e)} style={{ border: "none" }}>GET A QUOTE
                                    <i className="fa-regular fa-arrow-right ms-2"></i>
                                </button>

                            </div>
                        </div>
                    </div>

                </section>
                <section className="bg-smoke" id="process-sec" data-bg-src="assets/img/bg/process_bg_1.png">
                    <div className="space">
                        <div className="container">
                            <div className="title-area text-center">
                                <div className="shadow-title">PROCESS</div><span className="sub-title"><img
                                    src="assets/img/theme-img/title_shape_2.svg" alt="shape" />WORK PROCESS</span>
                                <ScrollAnimation animateIn="animate__animated animate__fadeInUp">
                                    <h2 className="sec-title">How we <span className="text-theme">work!</span></h2>
                                    <p className="">We believe that the key to delivering exceptional digital solutions lies in our meticulous and collaborative approach. <br></br>Here’s a glimpse into our process:</p>
                                </ScrollAnimation>

                            </div>
                            <div className="process-card-area">

                                <div className="row gy-40 work-progress">
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">01</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/needs.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Understanding Your Needs</h2>
                                            <p className="process-card_text">Every successful project begins with a thorough understanding of your goals and requirements. We start by engaging in detailed discussions with you to gather all necessary information. This helps us gain a clear understanding of your vision, target audience, and business objectives.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">02</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/planning.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Research and Planning</h2>
                                            <p className="process-card_text">With a solid understanding of your needs, our team conducts comprehensive research to explore the latest trends, technologies, and best practices relevant to your project. We then develop a strategic plan outlining the project scope, timelines, and milestones, ensuring we have a clear roadmap to follow.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">03</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/prototyping.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Design and Prototyping</h2>
                                            <p className="process-card_text">Our design team creates intuitive and visually appealing prototypes based on the initial requirements and research. These prototypes provide a tangible preview of the final product, allowing you to visualize the user experience and interface design. We gather your feedback and make necessary adjustments to ensure the design aligns perfectly with your vision.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">04</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/development.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Development</h2>
                                            <p className="process-card_text">Once the design is approved, our expert developers bring it to life using the latest technologies and coding standards. Whether it's a website, app, or game, we ensure that the development process is seamless and efficient. Our team focuses on creating a robust, scalable, and secure solution that meets your functional requirements.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">05</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/qa.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Testing and Quality Assurance</h2>
                                            <p className="process-card_text">Our dedicated QA team conducts rigorous testing at every stage of development to identify and resolve any issues. We perform functional, performance, and usability testing to ensure that the final product is flawless and delivers a superior user experience.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">06</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/deployment.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Launch and Deployment</h2>
                                            <p className="process-card_text">After thorough testing and final approvals, we prepare for the launch. Our team ensures a smooth deployment process, handling all technical aspects to make your website, app, or game live. We provide you with all the necessary support and guidance to ensure a successful launch.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">07</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/support.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Post-Launch Support and Maintenance</h2>
                                            <p className="process-card_text">Our commitment to your project doesn’t end at launch. We offer ongoing support and maintenance services to ensure your digital solution continues to perform optimally. Whether you need updates, enhancements, or technical assistance, our team is always here to help.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">08</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/improvement.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Continuous Improvement</h2>
                                            <p className="process-card_text">We believe in continuous improvement and staying ahead of the curve. We regularly review and analyze the performance of your solution, gathering user feedback and making data-driven decisions for future enhancements. Our goal is to ensure your digital solution remains relevant and effective in achieving your business objectives.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 process-card-wrap">
                                        <div className="process-card">
                                            <div className="process-card_number bg-blue">09</div>
                                            <div className="process-card_icon"><img src="assets/img/icon/items/collaboration.svg" alt="icon" />
                                            </div>
                                            <h2 className="box-title">Collaboration and Communication</h2>
                                            <p className="process-card_text">Throughout the entire process, we maintain open and transparent communication with you. We provide regular updates, seek your input, and ensure you are involved in every key decision. This collaborative approach helps us deliver a final product that truly reflects your vision and meets your expectations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="" style={{ backgroundColor: "#1c1c1c" }}>
                    <div className="z-index-common">
                        <div className="row justify-content-center m-0">
                            <div className="col-lg-6 col-md-6 col-sm-10  px-xl-4">
                                <div className="title-area text-center pt-100 mt-40"><span className="sub-title"><img
                                    src="assets/img/theme-img/title_shape_1.svg" alt="shape" />OUR TECHNICAL EXPERTISE</span>
                                    <h2 className="sec-title text-white set-max_height">Our Professionals <span className="text-theme">techs</span></h2>
                                    <p className="mt-n2 mb-30 text-white pt-40 m-auto">Our techs are industry veterans with years of experience in web, app, and game development. They possess a deep understanding of the latest technologies, tools, and best practices, enabling us to tackle even the most complex challenges with confidence and precision.</p>
                                </div>
                            </div>
                            <div className="main-lan col-auto">
                                <div className="content-ln">
                                    <div className=" d-block conetnt-ln-inner">

                                        <div className="service-featured">
                                            <div className="service-featured_content animate-one">
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="col-md-6 col-sm-8 col-xxl-6 Icon_img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="col-md-6 col-sm-6 col-xxl-6 Icon_img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="col-md-6 col-sm-6 col-xxl-6 Icon_img-three" />
                                            </div>
                                            <div className="service-featured_content animate-one">
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="col-md-6 col-sm-8 col-xxl-6 Icon_img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="col-md-6 col-sm-8 col-xxl-6 Icon_img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="col-md-6 col-sm-6 col-xxl-6 Icon_img-three" />
                                            </div>
                                        </div>

                                    </div>                                    
                                </div>
                            </div>
                            <div className="main-lan col-md-12 new_main_lan">
                                <div className="row content-ln d-flex">
                                    <div className="service-featured">
                                        <div className="service-featured_content animate-one">
                                            <div>
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="img-three" />
                                            </div>
                                            <div>
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="img-three" />
                                            </div>
                                            <div>
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="img-three" />
                                            </div>
                                            <div>
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="img-three" />
                                            </div>
                                            <div>
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="img-three" />
                                            </div>
                                            <div>
                                                <img src="assets/img/techs/new_k.png" alt="Icon" className="img-one" />
                                                <img src="assets/img/techs/new_kk.png" alt="Icon" className="img-two" />
                                                <img src="assets/img/techs/kkk.png" alt="Icon" className="img-three" />
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="space-bottom space-top contact-infos pb-0">
                    <div className="container">
                        <div className="row text-center pt-4">
                            <div className="col">
                                <h2 className="word-rotator slide font-weight-bold text-8 mb-25">
                                    <span>We're not the only ones&nbsp;</span>
                                    <span className="word-rotator-words bg-primary">
                                        <span className="spinny-words text-start">
                                            <span>excited</span>
                                            <span>happy</span>
                                            <span>excited</span>
                                            <span>happy</span>
                                            <span>excited</span>
                                            <span>happy</span>
                                        </span>                                      
                                    </span>
                                    <span>&nbsp;about Game Development.</span>
                                </h2>
                                <h4 className="text-primary lead tall text-4">7M CUSTOMERS IN 100 COUNTRIES USE OENGINES GAMES.</h4>
                            </div>
                        </div>
                        <div className="row text-center mt-5">
                            <div>
                                <OwlCarousel className='owl-theme' loop margin={10} {...sliderSettings} nav>
                                    <div>
                                        <img src="assets/img/language/logo-1.png" alt="img not found" />
                                    </div>
                                    <div>
                                        <img src="assets/img/language/logo-2.png" alt="img not found" />
                                    </div>
                                    <div>
                                        <img src="assets/img/language/logo-3.png" alt="img not found" />
                                    </div>
                                    <div>
                                        <img src="assets/img/language/logo-4.png" alt="img not found" />
                                    </div>
                                    <div>
                                        <img src="assets/img/language/logo-5.png" alt="img not found" />
                                    </div>
                                    <div>
                                        <img src="assets/img/language/logo-6.png" alt="img not found" />
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <section className="section section-custom-map appear-animation lazyloaded animated fadeInUpShorter appear-animation-visible mb-50" data-appear-animation="fadeInUpShorter" data-src-bg="img/map.png" style={{ backgroundColor: "transparent", backgroundPosition: "center 0px", backgroundRepeat: "no-repeat", animationdelay: "100ms" }}>
                    {
                        this.state.blog.length > 0 || this.state.clientfidback.length > 0 
                        ?
                            <section className="section section-default section-footer mb-80">
                                <div className="container">
                                    <div className="row mt-5 pt-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter">
                                        {this.state.blog.length ? 
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="recent-posts mb-5">
                                                <h2 className="font-weight-normal text-6 mb-4"><strong className="font-weight-extra-bold">Latest</strong> Posts</h2>
                                                {latestpostdata}
                                            </div>
                                        </div>
                                        :''
                                        }
                                        { this.state.clientfidback.length ? <div className="col-lg-6 col-md-6 col-sm-12">
                                            <h2 className="font-weight-normal text-6 mb-4"><strong className="font-weight-extra-bold">What</strong> Client’s Say</h2>
                                            <div className="row">
                                                {clientfideback}
                                            </div>
                                        </div>
                                        : ''
                                        }
                                    </div>
                                </div>
                            </section>
                        :
                        ''
                    }
                </section> */}
                {/* <div className="pb" id="pb"></div> */}
                <div className="pc">
                    <div className="ol">
                        <a href="#" id="btn-back-to-top">
                            <i className="fa-solid fa-arrow-up">
                            </i>
                        </a>
                    </div>
                    <div className="bg" id="bg"></div>
                </div>


                <Footer />

            </div>
        )
    }
}

export default App;
